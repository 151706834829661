import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Style from "../globals.module.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={Style.notFoundPane}>
      <h2>404 NOT FOUND</h2>
      <hr />
      Oops! This page doesn't exist.
    </div>
  </Layout>
)

export default NotFoundPage
